
import { ControlBaseProps } from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { IDocument } from "src/model/Esign/Document";
import { IConditionalControlRule } from "src/model/Esign/IConditionalControlRule";
import { ISignatureControlModel, SignatureControlType } from "src/model/ViewModel/DocumentViewModel";

export const getConditionalControlsList = (documents: IDocument[]): ISignatureControlModel[] => {
    let controlsList: ISignatureControlModel[] = [];

    if(documents && documents.length > 0) {

        for (let i = 0; i <= documents.length - 1; i++) {

            const conditionalControlRules = documents[i].conditionalControlsRules;

            if(conditionalControlRules && conditionalControlRules.length > 0){
                for (let j = 0; j <= conditionalControlRules.length - 1; j++) {

                    if(conditionalControlRules[j].conditionalControl.type == SignatureControlType.CheckBox){
                        const controlData = getControlValue(documents[i],conditionalControlRules[j].conditionalControl)
                        if(!controlData?.checked ){
                            for(let k = 0; k <= conditionalControlRules[j].controlOptionsRules.length - 1; k++) {
                                controlsList = [...controlsList, ...conditionalControlRules[j].controlOptionsRules[k].selectedControls];
                            }
                        }
                    }

                    else if(conditionalControlRules[j].conditionalControl.type == SignatureControlType.Radio){
                        const controlData = getControlValue(documents[i],conditionalControlRules[j].conditionalControl)
                        if(controlData && controlData?.items){
                            const checkedRadioButtonid = controlData.items.filter((item) => item.value.checked == true)[0]?.id;

                            for(let k = 0; k <= conditionalControlRules[j].controlOptionsRules.length - 1; k++) {
                                if(conditionalControlRules[j].controlOptionsRules[k].selectedOption.id != checkedRadioButtonid){
                                    controlsList = [...controlsList, ...conditionalControlRules[j].controlOptionsRules[k].selectedControls];
                                }
                            }

                        }

                        else{
                            for(let k = 0; k <= conditionalControlRules[j].controlOptionsRules.length - 1; k++) {
                                controlsList = [...controlsList, ...conditionalControlRules[j].controlOptionsRules[k].selectedControls];
                            }
                        }

                    }
                }
            }
        }

    }    

    return controlsList;
}


const getControlValue = (document:IDocument , control : ISignatureControlModel) : any => {

    const pages = document.pages;

    if(pages){

        for (var j = 0; j <= pages.length - 1; j++) { // document pages loop

            const controls = pages[j].controls;

            for (var k = 0; k <= controls.length - 1; k++) { // page controls loop

                if(controls[k].id === control.controlGuid){
                   return controls[k]?.data
                }

            }
        }
    }
}


export const getConditionalControlsRules = (documents: IDocument[]): IConditionalControlRule[] => {
    let conditionalControlsRules: IConditionalControlRule[] = [];

    if(documents && documents.length > 0) {

        for (let i = 0; i <= documents.length - 1; i++) {

            const conditionalControlRules = documents[i].conditionalControlsRules;

            if(conditionalControlRules && conditionalControlRules.length > 0){
                for (let j = 0; j <= conditionalControlRules.length - 1; j++) {

                    conditionalControlsRules = [...conditionalControlsRules, conditionalControlRules[j]];

                }
            }
        }

    }    

    return conditionalControlsRules;
    
}

export const getUpdatedConditionalControlsList = (conditionalControlsList:ISignatureControlModel[], conditionalControlRules:IConditionalControlRule[] ,controlData : any ,controlProps: ControlBaseProps  ): ISignatureControlModel[] => {
    let updatedControlsList: ISignatureControlModel[] = [...conditionalControlsList];

    //Only works if its Radio control or Checkbox control
    
    if (controlProps?.data["controlType"] == 9 || controlProps?.data["controlType"] == 11) {
        
        if(conditionalControlRules && conditionalControlRules.length > 0){

            for (let i = 0; i <= conditionalControlRules.length - 1; i++) {

                if(conditionalControlRules[i].conditionalControl.controlGuid == controlProps.data?.controlId) {

                    if(conditionalControlRules[i].conditionalControl.type == 9) { //Check Box control

                        for(let j = 0; j <= conditionalControlRules[i].controlOptionsRules.length - 1; j++) {

                            if(controlData?.checked) {
                                
                                for (let k = 0; k <= conditionalControlRules[i].controlOptionsRules[j].selectedControls.length -1; k++) {
                                    let currentControl = conditionalControlRules[i].controlOptionsRules[j].selectedControls[k];
                                    
                                    let index = updatedControlsList.findIndex(obj => obj.controlGuid === currentControl.controlGuid);
                                    
                                    if (index !== -1) {
                                        updatedControlsList.splice(index, 1);
                                    }
                                }
                            
                            }
                            else{

                                for (let k = 0; k <= conditionalControlRules[i].controlOptionsRules[j].selectedControls.length -1; k++) {
                                    let currentControl = conditionalControlRules[i].controlOptionsRules[j].selectedControls[k];
                                    
                                    let index = updatedControlsList.findIndex(obj => obj.controlGuid === currentControl.controlGuid);
                                    
                                    if (index == -1) {
                                        updatedControlsList.push(currentControl);
                                    }
                                }
                            }	

                        }

                    }

                    else if(conditionalControlRules[i].conditionalControl.type == 11){ //Radio control

                        for(let j = 0; j <= conditionalControlRules[i].controlOptionsRules.length - 1; j++) {

                            let slectiedOption = controlData?.filter((x:any) => x?.id == conditionalControlRules[i].controlOptionsRules[j].selectedOption.id)[0];
                            if(slectiedOption){
                                if(slectiedOption?.value?.checked) {
                                
                                    for (let k = 0; k <= conditionalControlRules[i].controlOptionsRules[j].selectedControls.length -1; k++) {
                                        let currentControl = conditionalControlRules[i].controlOptionsRules[j].selectedControls[k];
                                        
                                        let index = updatedControlsList.findIndex(obj => obj.controlGuid === currentControl.controlGuid);
                                        
                                        if (index !== -1) {
                                            updatedControlsList.splice(index, 1);
                                        }
                                    }
                                
                                }
                                else{

                                    for (let k = 0; k <= conditionalControlRules[i].controlOptionsRules[j].selectedControls.length -1; k++) {
                                        let currentControl = conditionalControlRules[i].controlOptionsRules[j].selectedControls[k];
                                        
                                        let index = updatedControlsList.findIndex(obj => obj.controlGuid === currentControl.controlGuid);
                                        
                                        if (index == -1) {
                                            updatedControlsList.push(currentControl);
                                        }
                                    }
                                }	
                            }
                        }
                    }
                }    
            }
        }
    }
    return updatedControlsList;
}

export const getRemovedConditonalDataDocuments = (documents: IDocument[], conditionalControlsList: ISignatureControlModel[], isFinal: boolean = false ): IDocument[] => {
    let tempDocuments: IDocument[] = isFinal ? documents  : JSON.parse(JSON.stringify(documents));

    for (let i = 0; i <= tempDocuments.length - 1; i++) { // doument loop

        const pages = tempDocuments[i].pages;

        for (let j = 0; j <= pages.length - 1; j++) { // document pages loop

            const controls = pages[j].controls;

            for (let k = 0; k <= controls.length - 1; k++) { // page controls loop

                const controlIndex = conditionalControlsList.findIndex((x) => x.controlGuid == controls[k].id);

                if (controlIndex !== -1) {
                    pages[j].controls = pages[j].controls.filter(x => x.id !== controls[k].id);
                }

            }
        }
    }

    return tempDocuments;
}

export const isControlValid = (control: any): boolean => {
    if ((control.props.data["controlType"] == 1)
        || (control.props.data["controlType"] == 2)) {
        if (control.props?.signatureData) {
            return true;
        }
    }
    else if ((control.props.data["controlType"] == 4)
        || (control.props.data["controlType"] == 5)
        || (control.props.data["controlType"] == 7)
        || (control.props.data["controlType"] == 8)
        || (control.props.data["controlType"] == 10)
        || (control.props.data["controlType"] == 6)) {
        if(control.props?.textboxData?.text && control.props?.textboxData?.text != ""){
            return true;
        }
    }
    else if ((control.props.data["controlType"] == 9)) {
        if(control.props?.checkBoxData?.checked){
            return true;
        }
    }
    else if ((control.props.data["controlType"] == 11)) {
        if(control.props?.items && control.props?.items.some(x => x.value.checked == true)){
            return true;
        }
    }
    else if (control.props.data["controlType"] == 3) {
        return true;
    }
    return false;
}