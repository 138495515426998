import { FirmType } from "src/model/ViewModel/ICompanyModel";

export const injectPendoScript = (props) => {
    const { id : CompanyId,
            companyName : CompanyName,
            isTestCompany: IsTestCompany} = props?.companyData;
    const {email: signer_Email} = props?.authenticationInfo;
    
    if (!CompanyId || !CompanyName || !signer_Email) {
        console.log("User profile not available yet");
        return;
    }

    const apiKey = process.env.REACT_APP_PENDO_API_KEY;

    (function (p: any, e: any, n: any, d: any, o: any) {
        var v: any, w: any, x: any, y: any, z: any;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
        for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
                o[m] =
                    o[m] ||
                    function () {
                        o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                    };
            })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo", "");

    window.pendo?.initialize({
        visitor: {
            id: `${CompanyId}-${signer_Email}`,
            email: signer_Email
        },
        account: {
            id: CompanyId,
            accountName: CompanyName,
            isTestCompany: IsTestCompany? FirmType[FirmType.Internal] : FirmType[FirmType.Live],
            payingStatus: "trial"
        }
    });
};