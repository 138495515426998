import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Common, MyAccountConstants, PathConstants } from '../../../common/constants/Constants';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { IUtilities } from '../../../core/utilities/Utilities';
import { ClientType } from '../../../model/Common/Enums';
import { ChangeNumber } from './ChangeNumber';
import { Header } from './header';
//import { MyAccount } from './MyAccount';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { inMemoryToken } from '../../../core/services/dataAccess/DataService.Axios';
import SignerHelper from '../signflow/Helper/SignerHelper';
import { SessionCheck } from '../Common/SessionCheck';
import { ILocalStore } from '../../../core/utilities/LocalStore';
import { ITokenData } from '../Login/Action/LoginActions';
import { IPublicUserTokenData } from '../signflow/Action/SignerSignFlowActions';
import { EMPTY_STRING, hideFinishButton } from '../../common/Helper/HelperFunctions';
import { injectPendoScript } from '../../common/CustomHooks/Pendo';

declare global {
    interface Window {
        Variables: any;
        pendo: any;
    }
}

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export type SlideBarProps =
    {
        primaryDetailsStore: IPublicData;
        tokenInfo: ITokenData;
        primaryTokenInfo: IPublicUserTokenData;
        requestPrimaryDetails(id: string,successCallback?: (data : any) => void): void;
        refreshToken: (clientId: string, callback?: () => void) => void;
        dispatchUserToken: (string: string) => void;
    }
    & RouteComponentProps<{}>;


const utilities = container.get<IUtilities>(TYPES.IUtilities);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export const SlideBar: React.FunctionComponent<SlideBarProps> = (props) => {

    const [layoutStates, setlayoutStates] = useState({
        showMyAccountPopUp: false,
        showAccessCodePopUp: false,
        emailAddress: "",
        mobileNumber: "",
        countryCode: "",
        ssn: "",
        showChangeNumberPopUp: false,
        showMyDownloadPopUp: false,
        screenShareInProgress: false
    });

    useEffect(() => {
        let param: any = props.match.params;
        if (param.Id) {
            (window as any).Variables.clientId = param.Id;
            props.requestPrimaryDetails(param.Id,(data) => injectPendoScript(data));
        } else {
            (window as any).Variables.clientId = param.clientId;
            props.requestPrimaryDetails(param.clientId);
        }
    }, []);



    const getMyaccount = () => {
        setlayoutStates((prevstates) => ({
            ...prevstates,
            showMyAccountPopUp: true
        }));
    }

    const onCancelMyAccountPopUp = () => {
        setlayoutStates((prevstates) => ({
            ...prevstates,
            showMyAccountPopUp: false
        }));
    }

    const onChangeMobileNumberClick = () => {
        setlayoutStates((prevstates) => ({
            ...prevstates,
            showChangeNumberPopUp: true
        }));
    }

    const onTaxClientMobileUpdateSuccess = (clientType: ClientType) => {
        switch (clientType) {
            default:
                snackbar.show(MyAccountConstants.StatusMessage.UpdateInformation);
                break;
        }
    }

    const onHideChangeNumberPopUp = () => {
        setlayoutStates((prevstates) => ({
            ...prevstates,
            showChangeNumberPopUp: false
        }));
    }


    const handleLogout = () => {
        let param: any = props.match.params;
        let history = props.history;
        dialogBox.confirm(Common.DialogBox.ConfirmationTitle,
            Common.DialogBox.ConfirmMessage,
            Common.DialogBox.No,
            Common.DialogBox.Yes, function (result: boolean) {
                if (result) {
                    //let clientId = SignerHelper.getClientId();
                    let clientId = param.clientId;
                    hideFinishButton();
                    inMemoryToken.delete(clientId);
                    localStore.remove('loggedIn');
                    history.push(`${PathConstants.Logout}${clientId}`);
                }
            });
    }
    
    const contactInfoPopover = (
        <Popover id="contactInfoPopover" placement="right" className="popover-contact">
            <h3 className="popover-header">Contact Information</h3>
            <div className="popover-body" data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298">
                <div className='medium'>
                    {props.primaryDetailsStore.primaryDetails.contactPersonInfo?.firstName} {props.primaryDetailsStore.primaryDetails.contactPersonInfo?.lastName}
                </div>
                <div className='mail'>
                    <a href={"mailto:" + props.primaryDetailsStore.primaryDetails.contactPersonInfo?.email}>
                        {props.primaryDetailsStore.primaryDetails.contactPersonInfo?.email}
                    </a>
                </div>
                <div>
                    <strong className="strong-phone">
                        {props.primaryDetailsStore.primaryDetails.contactPersonInfo?.phoneNumber &&
                        props.primaryDetailsStore.primaryDetails.contactPersonInfo?.phoneNumber != EMPTY_STRING &&
                        utilities.phoneNumberDisplay(props.primaryDetailsStore.primaryDetails.contactPersonInfo?.phoneNumber, props.primaryDetailsStore.primaryDetails.contactPersonInfo?.extension)}
                    </strong>
                </div>
            </div>
        </Popover>
    );


    const accountPopover = (
        <Popover id="accountPopover" placement="bottom" className='popover-myaccount'>
            <div className="popover-body"  >
                <ul className='account-menu'>
                    <li><Link to={"#"} onClick={() => getMyaccount()} data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606">
                        <i className="fa fa-user ddl-icon"></i><span>My Account</span></Link></li>
                </ul>
            </div>
        </Popover>
    );

    return (<React.Fragment>
        <div className="row header-container">
            <div className={"header-nav"}>
                <div className="header-left-container" data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">
                    <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                    </label>
                    <div id="sidebarMenu">
                        <ul className="sidebar navbar-nav ul-slidebar">

                            <li className="li-nav-first">
                                <h5 className="bold-text">{props.primaryDetailsStore.primaryDetails.clientName}</h5>
                            </li>

                            <li data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F" className="li-nav-second" >
                                <span className="span-nav" title="Contact Person's Information">
                                    <OverlayTrigger
                                        data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
                                        rootClose trigger="click"
                                        onEnter={() => document.body.click()}
                                        overlay={contactInfoPopover}
                                        placement="right">
                                        <a>
                                            Contact Tax Firm
                                        </a>
                                    </OverlayTrigger>
                                </span>

                            </li>

                            {/*
                                    <li className="li-nav">
                                        <span className="span-nav">
                                            <a onClick={() => getMyaccount()} data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606">
                                                My Account
                                        </a>
                                        </span>
                                    </li>
                                */}

                            {localStore.get('loggedIn') && ((props.tokenInfo.token != "undefined" && props.tokenInfo.token != "")
                                || (props.primaryTokenInfo.token != "undefined" && props.primaryTokenInfo.token != "")) &&
                                <li className="li-nav">
                                    <span className="span-nav" onClick={handleLogout}>Sign Out</span>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                <Header companyName={props.primaryDetailsStore.primaryDetails.companyData.companyName} companyLogo={""} />
            </div>
        </div>

        <ChangeNumber
            onHideChangeNumberPopUp={onHideChangeNumberPopUp}
            showState={layoutStates.showChangeNumberPopUp}
            mobileNumber={""}
            countryCode={""}
            onSaveNumberClick={() => alert()}
        />

        {/*
                <MyAccount
                    key="value"
                    onHide={onCancelMyAccountPopUp}
                    showState={state.showMyAccountPopUp}
                    onChangeNumberClick={onChangeMobileNumberClick}
                    onSaveButtonClick={() => alert()}
                    taxDocument={() => alert()}
                    partnerShip={undefined}
                    taxPayer={undefined}
                    spouse={undefined} />
            */}

        {
            !localStore.get('loggedIn')
                ? props.children :
                props.primaryTokenInfo.token || props.tokenInfo.token
                    ? props.children : null
        }

        {
            localStore.get("loggedIn") ?
                <SessionCheck
                    refreshToken={props.refreshToken}
                    dispatchUserToken={props.dispatchUserToken}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                /> : ""
        }
    </React.Fragment >);
}