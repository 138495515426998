import { container } from "../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../core/startup/inversify/types";
import { FileUtilities } from "../../../../core/utilities/File/FileUtilities";
import { ILoader } from "../../../../core/utilities/ui/Loader";
import SignerHelper from "../../signflow/Helper/SignerHelper";
import { DownloadStep } from '../../../../model/Common/Enums';
import { axiosFetch } from "../../../../core/services/dataAccess/DataService.Axios";
import { IDocumentInfo } from '../../../../model/ViewModel/DocumentInfo';
import { actionTypes } from "../../../../common/constants/actionTypes";

const fileUtilities = container.get<FileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

export const downloadAllDocument = (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep, isMigratedSigningIdForAudit: boolean = false) => (dispatch: any) => {

    //const clientId: string = SignerHelper.getClientId();
    loader.show();
    var config = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' }, withCredentials: true };
    axiosFetch(clientId).getWithConfig('api/Download/DownloadAllSignedDocumentAsync?'
        + "fileName=" + encodeURIComponent(fileName) + "&isSigningCompleted=" + isSigningCompleted + "&downloadStep=" + downloadStep + "&isMigratedSigningId=" + isMigratedSigningIdForAudit, config)
        .then(function (response: any) {
            fileUtilities.show(response.data, fileName);
            loader.hide();
        }).catch(function (error: any) {
            loader.hide();
        });
};

export const requestDocuments = (clientId: string,) => (dispatch: any) => {

    //const clientId: string = SignerHelper.getClientId();
    loader.show();

    axiosFetch(clientId).get('api/Signing/GetAllDocumentsAsync')
        .then(function (response: any) {
            const { data } = response;
            dispatch({
                type: actionTypes.RECEIVE_DOCUMENTS, documents: data
            });
            loader.hide()
        }).catch(function (error: any) {
            loader.hide();
        });
};


export interface IDownloadData {
    documents: IDocumentInfo[],
}

const unloadedState: IDownloadData = {
    documents: [
        {
            documentGuid: "",
            documentType: "",
            documentTypeId: 0,
            expiresOn: new Date(),
            id: 0,
            name: "",
            signingId: 0
        }
    ]
} as IDownloadData;


export const downloadsReducer = (state: IDownloadData = unloadedState, action: any) => {
    switch (action.type) {

        case actionTypes.RECEIVE_DOCUMENTS: {
            return {
                documents: action.documents,
            } as IDownloadData;
        }
    }
    return state || unloadedState;
}