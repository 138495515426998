import React, { useState, useEffect, useRef } from 'react';
import { Step } from 'awesome-multi-steps/dist/Body/Step';
import { Wizard } from 'awesome-multi-steps/dist/Body/Wizard';
import { Common, PathConstants, SignProcessConstants } from '../../../common/constants/Constants';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { ClientTypes, DownloadStep } from '../../../model/Common/Enums';
import { ITaxingAuthority } from '../../../model/Common/TaxingAuthority';
import { IDocument } from '../../../model/Esign/Document';
import { Esign } from '../Sign/Esign/Sign/Esign';
import { LandingPage } from '../LandingPage/LandingPage';
import SignerHelper from './Helper/SignerHelper';
import { SignProcessSteps, SignerSignFlowHelper, IPublicData } from './Helper/SignerSignFlowHelper';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { ISignerData } from '../Sign/Esign/Action/EsignActions';
import { initialClientSignatureStatus, IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { IEsignValidationModel, EsignManager } from '../../.././model/Esign/Base/EsignBase';
import { ISignerControlDataModal } from '../../.././model/Esign/ISignerControlDataModal';
import { DocumentAdapter } from '../Sign/Esign/Sign/Helper/ControlDataAdapter';
import { RouteComponentProps } from 'react-router';
import { ITokenData } from './../Login/Action/LoginActions';
import { hideFinishButton } from "../../common/Helper/HelperFunctions";
import { ISignFinishedResponse } from 'src/model/ViewModel/ISignFinishedResponse';

export type SignerSignFlowProps = {
    taxingAuthorities: ITaxingAuthority[];
    primaryDetailsStore: IPublicData;
    signatureInfoStore: ISignerData;
    tokenInfo: ITokenData;
    signatureControlsData: IDocument[];

    fetchControlData(): void;
    updateSignatureControlsData(data: IDocument[]): void;
    downloadAllDocument(clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep): void;
    assignToDelegatee: (clientid: string, email: string, name: string, reason: string, callback: (status: boolean) => void) => void;
    requestSignatureInfo: (clientid: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    declineSigning: (clientid: string, remarks: string, callback?: (status: boolean) => void) => void;
    requestDocumentControls: (clientId: string, isControlsDisabled: boolean, skipTracking?: boolean, callback?: () => void) => void;
    reAssignToDelegatee: (clientid: string, email: string, name: string, reason: string, callback: (status: boolean) => void) => void;
    delegationCancelled: (clientid: string, callback: (status: boolean) => void) => void;
    sign: (clientId: string, signatureData: ISignerControlDataModal, callback?: (status: ISignFinishedResponse) => void) => void;
    refreshToken: (clientId: string, callback?: () => void) => void;
}
    & RouteComponentProps<{}>


const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export const SignerSignFlow: React.FunctionComponent<SignerSignFlowProps> = (props) => {


    const _wizardRef = useRef<any>();
    const _consentRef = useRef<any>();
    const _esignRef = useRef<any>();
    const _clientId: React.MutableRefObject<string> = useRef("");
    const _SignerSignFlowManager = useRef<SignerSignFlowHelper>();
    _SignerSignFlowManager.current = SignerSignFlowHelper.createNullObject();

    const [signerStates, setSignerStates] = useState({
        hideNextButton: false,
        hidePreviousButton: false,
        isNextButtonDisable: false,
        isConsentAgreed: false,
        hideNavigationBar: false,
        hideFooter: false,
        showDelegatePopUp: false,
        showDelegateHelpPopUp: false,
        showDeclineSigningPopUp: false,
        emailAddress: "",
        signerName: "",
        changeReason: ""
    });


    useEffect(() => {
        let param: any = props.match.params;
        (window as any).Variables.clientId = param.Id;
        _clientId.current = param.clientId;
        getSignProcessStatus(param.clientId);
    }, []);


    const getSignProcessStatus = (clientId: string) => {
        if (props.signatureInfoStore.SignatureStatus != initialClientSignatureStatus) {
            props.requestDocumentControls(clientId, props.signatureInfoStore.AssignToDelegateeSigner);
        }
        else {
            props.requestSignatureInfo(clientId, () => {
                props.requestDocumentControls(clientId, props.signatureInfoStore.AssignToDelegateeSigner);
            });
        }

        if (props.signatureInfoStore.AssignToDelegateeSigner == true ||
            (props.signatureInfoStore.SignatureStatus.delegateeId != null && props.signatureInfoStore.SignatureStatus.delegateeId > 0 &&
                props.signatureInfoStore.SignatureStatus.clientType != ClientTypes.Delegatee)) {

            setSignerStates((prevstate) => ({
                ...prevstate,
                isNextButtonDisable: true
            }));
        }
    }

    //const signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {

    //    if (lastVisitedStep !== SignProcessSteps.SignerProcessComplete) {
    //        _SignerSignFlowManager.current.signProcessRecoverConfirmation(lastVisitedStep, _clientId.current);
    //    }
    //}

    const goToStep = (stepNumber: number) => {
        _wizardRef && _wizardRef.current.goToStep(stepNumber);
    }

    const goToStepById = (id: any) => {
        _wizardRef && _wizardRef.current.goToStepById(id);
    }

    const goToNextStep = () => {
        _wizardRef && _wizardRef.current.goToNextStep();
    };

    const goToPreviousStep = () => {
        _wizardRef && _wizardRef.current.goToPreviousStep();
    };

    const onConsentWizardNextBtnClick = (currStep: number) => {
        _consentRef.current.onNext(currStep);
    }

    const onConsentWizardPreviousBtnClick = (currStep: number) => {
        _consentRef.current.onPrevious(currStep);
    }

    const handleDisableNextButton = (value: boolean) => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            isNextButtonDisable: value
        }));
    }

    const handleHeaderAndFooterVisibility = (hidden: boolean) => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            hideNavigationBar: hidden,
            hideFooter: hidden
        }));
    }

    const handleStepChange = (step: number, stepId: string) => {

    }

    const resetWizardProps = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            hideNextButton: false,
            hidePreviousButton: false,
            isNextButtonDisable: false
        }));
    }

    const handleConsentAgreed = (value: boolean) => {
        handleDisableNextButton(!value);
        setSignerStates((prevstates) => ({
            ...prevstates,
            isConsentAgreed: value
        }));
    }

    const handleEsignStepNext = (currStep: number) => {
        let param: any = props.match.params;
        const documentData: IDocument[] = _esignRef.current._pdfView.getTempDocumentsData();

        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (validation.status) {
            const finalDocumentsData: IDocument[] = _esignRef.current._pdfView.getFinalDocumentsData();
            let signerControlDataModal: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(finalDocumentsData);
            signerControlDataModal.clientGuid = _clientId.current;
            props.sign(_clientId.current, signerControlDataModal, onSigningCallback);
        }
        else {
            _esignRef.current._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
    }

    const onSigningCallback = (signFinishResponse: ISignFinishedResponse) => {
        if (signFinishResponse.isSignFinish === true) {
            hideFinishButton();  //Updation of Remove Finish button is issue in Awesome-pdf-viewer this function is just for hiding the Finsh button for the bug-80417
            props.history.push('/signingsuccess/' + _clientId.current);
        }
        else if(signFinishResponse.signFinishMessage === SignProcessConstants.DocumentAlreadySigned){
            snackbar.show(signFinishResponse.signFinishMessage);
        }
        else {
            snackbar.show("Signing process failed!");
        }
    }

    const onHideDelegateHelpClick = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDelegateHelpPopUp: false
        }));
    }

    const onShowDelegatePopup = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDelegatePopUp: true
        }));
    }

    const onHideDelegatePopUp = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDelegatePopUp: false
        }));
    }

    const onShowDelegateHelpPopUp = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDelegateHelpPopUp: true
        }));
    }

    const onShowDeclineSigningPopUp = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDeclineSigningPopUp: true
        }));
    }

    const onHideDeclineSigningPopUp = () => {
        setSignerStates((prevstates) => ({
            ...prevstates,
            showDeclineSigningPopUp: false
        }));
    }


    const onDelegateSaveClick = (signerName: string, emailAddress: string, changeReason: string) => {
        let param: any = props.match.params;

        setSignerStates((prevstates) => ({
            ...prevstates,
            signerName: signerName,
            emailAddress: emailAddress,
            changeReason: changeReason
        }));
        props.assignToDelegatee(param.clientId, emailAddress, signerName, changeReason, onAssignToDelegateeCallBack);
    }

    const onAssignToDelegateeCallBack = (status: boolean) => {

        const senderName = signatureInfoStore.SignatureStatus.senderName;
        if (status === true) {
            snackbar.show("You've changed the Signer. We've notified the " + senderName + " and the new Signer. You'll receive an email copy once everyone has signed.", 10000);
        }
        else {
            snackbar.show("Assign to new signer failed!");
        }
        onHideDelegatePopUp();
    }

    const onDelegateReassignClick = (signerName: string, emailAddress: string, changeReason: string) => {
        let param: any = props.match.params;
        setSignerStates((prevstates) => ({
            ...prevstates,
            signerName: signerName,
            emailAddress: emailAddress,
            changeReason: changeReason
        }));
        props.reAssignToDelegatee(param.clientId, emailAddress, signerName, changeReason, onReAssignToDelegateeCallBack);
    }

    const onReAssignToDelegateeCallBack = (status: boolean) => {
        if (status) {
            onHideDelegatePopUp();
            snackbar.show("Delegated signer updated successfully!");
        } else {
            onHideDelegatePopUp();
            snackbar.show("Cannot update delegated signer.");
        }
    }

    const onDelegateCancelClick = () => {
        let param: any = props.match.params;
        props.delegationCancelled(param.clientId, onDelegationCancelCallBack);
    }


    const onDelegationCancelCallBack = (status: boolean) => {
        if (status) {
            snackbar.show("Delegation cancelled successfully!");
        }
        else {
            snackbar.show("Cannot cancel delegation");
        }
        onHideDelegatePopUp();
    }

    const DeclineSigning = (declineReason: string) => {
        let param: any = props.match.params;
        props.declineSigning(param.clientId, declineReason, onDeclineSigningSuccess);
    }

    const onDeclineSigningSuccess = (status: boolean) => {
        if (status === true) {
            props.history.push(PathConstants.Declined + _clientId.current)
        }
        else {
            snackbar.show("Declining your signing process failed!");
        }

        onHideDelegatePopUp();
    }

    const handleSigningCompleted = () => {
        handleDisableNextButton(false);
    }

    const { taxingAuthorities, primaryDetailsStore, signatureInfoStore } = props;

    //const completedPercentage = PdfViewManager.getControlValueUpdatedPercentage(props.signatureControlsData);

    return (<React.Fragment>

        <Wizard hideTopNavigation={true} ref={(ref: any) => {
            _wizardRef.current = ref;
            _SignerSignFlowManager.current = SignerSignFlowHelper.create(ref, props);
        }} onStepChange={handleStepChange} disableMobileResponsiveness={true} submitLabel={Common.Wizard.SubmitButtonLabel}>


            <Step id={SignProcessSteps.LandingPage.toString()}
                tooltip={"LandingPage"}
                previousLabel={Common.Wizard.PreviousButtonLabel}
                nextLabel={Common.Wizard.NextButtonLabel}
                hideNextButton={true}
                hidePreviousButton={true}>

                <LandingPage
                    taxingAuthorities={taxingAuthorities}
                    onShowDelegatePopup={onShowDelegatePopup}
                    onNext={goToNextStep}
                    onCancelDelegatePopUp={onHideDelegatePopUp}
                    showDelegatePopUp={signerStates.showDelegatePopUp}
                    onHideDelegateHelpClick={onHideDelegateHelpClick}
                    showDelegateHelpPopUp={signerStates.showDelegateHelpPopUp}
                    onShowDelegateHelpPopUp={onShowDelegateHelpPopUp}
                    onShowDeclineSigningPopUp={onShowDeclineSigningPopUp}
                    onHideDeclineSigningPopUp={onHideDeclineSigningPopUp}
                    showDeclineSigningPopUp={signerStates.showDeclineSigningPopUp}
                    onDelegateSaveClick={onDelegateSaveClick}
                    signerName={signerStates.signerName}
                    emailAddress={signerStates.emailAddress}
                    changeReason={signerStates.changeReason}
                    DeclineSigning={DeclineSigning}
                    primaryDetailsStore={primaryDetailsStore}
                    signatureInfoStore={signatureInfoStore}
                    onDelegateReassignClick={onDelegateReassignClick}
                    delegationCancelled={onDelegateCancelClick}
                />

            </Step>


            <Step id={SignProcessSteps.Esign.toString()}
                tooltip={"Esign"}
                //disableNextButton={completedPercentage < 100}
                disableNextButton={signerStates.isNextButtonDisable}
                disableNavigationClick={true}
                preventNextButtonDefaultBehaviour={true}
                onNext={handleEsignStepNext}
                previousLabel={"Cancel"}
                nextLabel={"Finish"}
                hideScrollbar={true}
                hidePreviousButton={false}
            >

                <Esign
                    clientId={_clientId.current}
                    ref={(ref: any) => { _esignRef.current = ref }}
                    onHeaderAndFooterVisibility={handleHeaderAndFooterVisibility}
                    requestSignatureControls={props.fetchControlData}
                    signatureControlsData={props.signatureControlsData}
                    onSigningComplete={props.updateSignatureControlsData}
                    disableNextButton={handleDisableNextButton}
                    completeSigningStep={() => { SignerHelper.redirectToSignCompletedPage(_clientId.current) }}
                    sign={props.sign}
                    signatureInfoStore={signatureInfoStore}
                    downloadAllDocument={props.downloadAllDocument}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                />

            </Step>

            <Step id={SignProcessSteps.Empty.toString()}
                tooltip={"Empty"}
            >
            </Step>
        </Wizard>
    </React.Fragment>);
}